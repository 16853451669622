import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import checkout from "../../../assets/images/checkout.png";
import toparrow from "../../../assets/images/toparrow.png";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
export default connect((props) => {
  const history = useHistory();
  //console.log(props);
  const [couponCode, setCouponCode] = useState(
    props?.store?.currentCoupon || ""
  );
  const [loading, setLoading] = useState(false);
  const [alertFail, setAlertFail] = useState(null);
  const onClickPromotion = (data) => {
    // console.log(data)
    if (data && data.couponCode) {
      mixins.setStore("seletedpromotion", data, props);
      setCouponCode(data.couponCode);
      mixins.setStore("applied", false, props);
    }
  };
  const getCartData = () => {
    setLoading(true);
    api.getCartDetails().then((res) => {
      ///console.log("CartData:", res);
      setLoading(false);
      // props.props.subTotal = res.data.totalTax;
      // props.props.subTotal = res.data.subTotal;
      // props.props.totalNumberOfBooks = res.data.totalNumberOfBooks;
      props.props.totalTax = res.data.totalTax;
      mixins.setStore("finalAmount", totalWithTax(res.data.subTotal), props);
      mixins.setStore("totaltaxamount", res.data.totalTax, props);
    });
  };
  useEffect(() => {
    mixins.setStore("currentCoupon", couponCode, props);
  }, []);
  const totalWithTax = (subTotal) =>
    (Number(subTotal) + Number(props?.props?.totalTax)).toFixed(2);
  useEffect(() => {
    if (!couponCode) {
      mixins.setStore("applied", "", props);
      mixins.setStore("dicount", "", props);
    } else {
      onClickApplyPromotion();
    }
    if (props.props.subTotal) {
      mixins.setStore("finalAmount", totalWithTax(props.props.subTotal), props);
      mixins.setStore("totaltaxamount", props.props.totalTax, props);
    }
    mixins.setStore("seletedpromotion", {}, props);
  }, [props.props.subTotal]);
  const onClickApplyPromotion = () => {
    //console.log(props.store);
    setLoading(true);
    api.getCartDetails().then((res) => {
      let totalTax = res.data.totalTax;
      let subtotal = res.data.subTotal;
      let bookNumber = res.data.totalNumberOfBooks;
      let bookIds = res.data.cart.map((item) => item.customBookDetails.bookId);
      // console.log(bookIds);
      api
        .getPromotionValidation({
          promotionTypeId:
            props.store?.seletedpromotion?.promotionTypeId || null,
          couponCode: couponCode,
          totalAmount: subtotal,
          quantity: bookNumber,
          //cart_ids:props.store.cart_list.cart.map(o=>o.id),
          bookIds: bookIds,
        })
        .then((res) => {
          if (res && res?.statusCode === 200 && res.data) {
            
            props.props.totalTax = res.data.taxApplied;
            mixins.setStore("totaltaxamount", res.data.taxApplied, props);
            mixins.setStore(
              "finalAmount",
              totalWithTax(res.data.amount),
              props
            );
            mixins.setStore("applied", true, props);
            mixins.setStore("dicount", res.data.discount, props);
            mixins.setStore("currentCoupon", couponCode, props);
            setAlertFail("Coupon Applied Successfully");
            
            setTimeout(() => {
              setAlertFail(null);
              setLoading(false);
            }, 3000);
          } else {
            mixins.setStore("currentCoupon", "", props);
            setCouponCode("");
            setLoading(false);
            mixins.setStore("seletedpromotion", {}, props);
            if (res && res.errorMessages) {
              setAlertFail(res.errorMessages[0]);
              setTimeout(() => {
                setAlertFail(null);
              }, 3000);
            } else {
              setAlertFail(res.message);
              setTimeout(() => {
                setAlertFail(null);
              }, 3000);
            }
          }
        });
    });
  };

  const onCouponChange = (e) => {
    setCouponCode(e.target.value);
    mixins.setStore("applied", false, props);
  };
  const removeCoupon = () => {
    setCouponCode("");
    mixins.setStore("applied", "", props);
    mixins.setStore("dicount", "", props);
    mixins.setStore("currentCoupon", "", props);
    getCartData();
  };
  const coupon_list = props.store.PromotionList && props.store.PromotionList;
  return (
    <>
      {props.props.totalNumberOfBooks > 0 && (
        <div className="BillingAddress">
          <div className="order-title">Order Summary</div>
          <div className="contents">
            {/* <div className="order-subtitle">
              Total Number of Books ({props.props.totalNumberOfBooks})
            </div> */}
            {props.props.cart.map((o) => {
              return (
                <div className="order-subtitle-row custom">
                  <div className="order-item">{o?.product?.bookTitle}</div>
                  <div className="order-price">SGD {o?.price}</div>
                </div>
              );
            })}
            <div className="order-subtitle-row">
              <div className="order-item">
                Sub Total ({props.props.totalNumberOfBooks} items)
              </div>
              <div className="order-price">SGD {props.props.subTotal}</div>
            </div>

            {loading ? (
              <>
                <div className="loadercsscus">
                  <CircularProgress color="success" />
                </div>
              </>
            ) : (
              <>
                {props.store.dicount && (
                  <div className="order-subtitle-row">
                    <div className="order-item">Discount Price</div>
                    <div className="order-price">SGD {props.store.dicount}</div>
                  </div>
                )}
                {/* <div className="taxheading">
              <div className="order-item">Tax</div>
            </div>
            {props.props.cart.map((o) => {
              return (
                <div className="order-subtitle-row custom">
                  <div className="order-item">
                    {o?.product?.bookTitle} &nbsp;
                    {o.product.taxAmount && (
                      <span>
                        @ {o?.product?.taxAmount}
                        {o?.product?.type == "Percentage" ? "%" : "Flat"}
                      </span>
                    )}
                  </div>
                  <div className="order-price">SGD {o.taxApplied}</div>
                </div>
              );
            })} */}
                {/* <div className="order-subtitle-row ">
                  <div className="order-item">Total Tax</div>
                  <div className="order-price">SGD {props.props.totalTax}</div>
                </div> */}
                <div className="order-subtitle-row">
                  <div className="order-item">Total Order Price</div>
                  <div className="order-price">
                    SGD {props.store.finalAmount && props.store.finalAmount}
                  </div>
                </div>
              </>
            )}

            <div className="discount" style={{ display: "none" }}>
              <div className="discount-subtitle-row">
                <div>Got a discount code? Select One or Enter</div>
                {/* <div>
              <img src={toparrow} />
            </div> */}
              </div>
              {props.props.totalNumberOfBooks > 0 && (
                <div className="discount-offer">
                  <select
                    className="discount-item"
                    onChange={(e) =>
                      onClickPromotion(
                        coupon_list.find((o) => o.couponCode == e.target.value)
                      )
                    }
                  >
                    <option value="">Select the coupon</option>
                    {coupon_list?.map((item, index) => {
                      return <option key={index}>{item.couponCode}</option>;
                    })}
                  </select>
                </div>
              )}
            </div>
            {/* {props.store.seletedpromotion &&
          props.store.seletedpromotion.couponCode && ( */}
            <div className="discount-code-row">
              <div className="discount-code-row-label">
                <span>
                  Discount Code:
                  <span className="discopuntcode">
                    <input
                      type="text"
                      onChange={onCouponChange}
                      value={couponCode}
                    />
                    {props.store.applied &&
                      <i className="fas fa-times" onClick={removeCoupon}></i>
                    }
                   
                  </span>
                </span>
              </div>
              {/* <div
                style={{
                  display: loading,
                  margin: "auto",
                  position: "relative",
                }}
              >
                <CircularProgress color="success" />
              </div> */}
              <div
                className={"apply-btn " + (couponCode ? "" : "disabled")}
                onClick={!props.store.applied && onClickApplyPromotion}
              >
               <span className= 'coupon-btn-text'> {props.store.applied ? "APPLIED" : "APPLY"}</span>
              </div>
            </div>
            {/* )} */}
            <h6 style={{ color: "red", marginBottom: "5px" }}>
              {alertFail && alertFail}
            </h6>
            <div
              className="checkout-btn"
              onClick={() => {
                if (props.props.totalNumberOfBooks > 0) {
                  history.push("/delivery");
                } else {
                  alert("Your Cart is Empty.");
                }
              }}
            >
              <img src={checkout} /> <span>CHECK OUT</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
});
