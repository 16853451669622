import React, { useEffect, useRef, useState } from "react";

const Firstname = (props) => {
  const {
    formdata,
    setFormdata,
    handlePreinfoSave = () => {},
    handlebackbtn = () => {},
  } = props;
  const [alert, setAlert] = useState(null);
  const [limit, setLimit] = useState(16);
  const name = props.store?.book?.worldbookPreInfo?.answer?.name;
  const nameRef = useRef();
  const handleChange = (val) => {
    setFormdata(val);
  };
  const handlesubmit = (e) => {
    e.preventDefault();
    if (!formdata?.name?.trim()) {
      setAlert("Field is required.");
      nameRef.current.focus();
    } else {
      handlePreinfoSave();
    }
  };
  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(null);
      }, 2000);
    }
  }, [alert]);
  useEffect(() => {
    if (formdata?.name) {
      setLimit(16 - formdata?.name?.length || 0);
    } else {
      setLimit(16);
    }
  }, [formdata]);
  return (
    <div>
      <form id="msform" onSubmit={handlesubmit}>
        <fieldset>
          <div className="form-card">
            <div className="row">
              <div className="col-12 canvas-info">
                <h2 className="fs-title">Who is this book for? </h2>
                <p>
                  Enter the person’s first name, and it will appear on the cover
                  and in surprising places in the book. Or if this is a
                  commemorative book, enter your graduating class name, or
                  school, or event!
                </p>
              </div>
            </div>
            <input
              ref={nameRef}
              type="text"
              className={"m-0"}
              placeholder="Please enter your name or class name"
              value={formdata?.name || ""}
              onChange={(e) => {
                mixins.preventEmoji(e);
                handleChange({ ...formdata, name: e.target.value });
              }}
              maxLength={16}
            />
            <span className="limit">{limit}</span>

            {alert && (
              <span
                style={{
                  color: "red",
                }}
              >
                {alert}
              </span>
            )}
          </div>
          <div
            className="d-flex align-items-center justify-content-end"
            style={{
              gap: "10px",
            }}
          >
            <span onClick={handlebackbtn} className="back-link">
              &lt; Back to the product page
            </span>
            {!name && (
              <button className="action-button" onClick={handlesubmit}>
                Save & Preview
              </button>
            )}
          </div>
        </fieldset>
      </form>
    </div>
  );
};

export default connect(Firstname);
