import { connect } from "react-redux";
import mixins from ".";
import { parse, stringify, toJSON, fromJSON } from "flatted";
const isset = (val, def = undefined) => {
  return val === undefined ? def : val;
};
const setStore = function (key, value = null, props = null) {
  props = props || this.props;
  let dispatch = props.dispatch;
  let watch = props.watch || this.watch;
  return dispatch((state) => {
    let storeObject = { ...state };
    let updateStore = (payload, storeObject) => {
      let key = payload.name;
      let watchme = watch[key] || ((v) => v);
      let arr = payload.name.split(".");
      if (arr.length > 1) {
        let tmp = storeObject,
          key = "",
          old = null;
        arr.forEach((k) => {
          old = tmp;
          tmp[k] = tmp[k] || {};
          tmp = tmp[k];
          key = k;
        });
        // arr.forEach(k => {
        //     old = tmp, tmp = tmp[k], key = k;
        // });
        old[key] = isset(watchme(payload.value), payload.value);
      } else {
        storeObject[key] = isset(watchme(payload.value), payload.value);
      }
      return storeObject;
    };
    if (typeof key == "object" && key instanceof Array) {
      key.map((obj) => {
        storeObject = updateStore(obj, storeObject);
      });
    } else {
      storeObject = updateStore({ name: key, value: value }, storeObject);
    }
    //storeObject = JSON.stringify(storeObject).parse();
    //localStorage.setItem("reduxStore",JSON.stringify(storeObject));
    return {
      ...storeObject,
    };
  });
};
export default {
  connect(component) {
    return connect(
      (state) => ({ store: state }),
      (dispatch) => {
        let ret = {};
        ret.dispatch = (func, ...payload) =>
          dispatch.bind(ret)({ type: func, payload: payload });
        ret.setStore = (key, value, props = {}) =>
          setStore.bind(ret)(key, value, { ...props, dispatch: ret.dispatch });
        ret.watch = {};
        return ret;
      }
    )(component);
  },
  initialConstruct(key) {
    this.$store = this.props.store;
    this.dispatch = this.props.dispatch;
    this.history = this.props.history || window.reduxHistory;
    this.setStore = mixins.setStore.bind(this);
    this.setStates = mixins.setStates.bind(this);
    this.debounce = mixins.debounce.bind(this);
    this.lasycall = mixins.lasycall.bind(this);
    this.watch = mixins.watch.bind(this);
    window[key] = this;
  },
  getCurrentPath() {
    return window.reduxHistory ? window.reduxHistory.location.pathname : "";
  },
  getDotedKeyValue(key, obj) {
    let arr = key.split(".");
    if (arr.length > 1) {
      let tmp = obj,
        key = "",
        old = null;
      arr.forEach((k) => {
        (old = tmp), (tmp = tmp[k]), (key = k);
      });
      return old[key];
    } else {
      return obj[key];
    }
  },
  getDotedValueKeys(key, obj) {
    let arr = key.split(".");
    if (arr.length > 1) {
      let tmp = obj || {},
        key = "",
        old = null;
      arr.forEach((k) => {
        (old = tmp), (tmp = tmp[k]), (key = k);
      });
      return {
        obj: old,
        key,
      };
    } else {
      return {
        obj,
        key,
      };
    }
  },
  setStore,
  setStates(key, val) {
    let self = this;
    return new Promise((res, rej) => {
      self.setState(
        (...arg) => {
          let ret = mixins.getDotedValueKeys(key, arg[0]);
          let obj = ret.obj;
          ret.obj[ret.key] = typeof val == "function" ? val(...arg) : val;
          // key = ret.key;
          // let obj = {};
          // obj[key] = (typeof val == "function") ? val(...arg) : val;
          return {
            ...arg[0],
          };
        },
        (...arg) => {
          res(...arg);
        }
      );
    });
  },
  watch(watchers, nextState = null, prevState = null) {
    prevState = prevState || { ...this.props };
    //console.log({nextState,prevState});
    let keys = Object.keys(watchers);
    keys.map((key) => {
      let oldval = mixins.getDotedKeyValue(key, prevState);
      let newval = mixins.getDotedKeyValue(key, nextState);
      if (mixins.detectComplexChange(oldval, newval)) {
        let func = watchers[key];
        if (func instanceof Function) {
          func.bind(this)(newval, oldval);
        }
      }
    });
  },
  detectComplexChange(prev, next) {
    let trusy = prev || next;
    if (typeof prev != typeof next || prev == null || next == null) {
      return prev != next;
    } else if (["object"].includes(typeof trusy)) {
      let nextLen =
        trusy instanceof Array ? next.length : Object.entries(next).length;
      let prevLen =
        trusy instanceof Array ? prev.length : Object.entries(prev).length;
      if (prevLen != nextLen) {
        return true;
      } else {
        return JSON.stringify(prev) != JSON.stringify(next);
      }
    } else {
      return prev != next;
    }
  },
  getSelectionText() {
    var txt = "";
    if (window.getSelection) {
      txt = window.getSelection().toString();
    } else if (document.getSelection) {
      txt = document.getSelection().toString();
    } else if (document.selection) {
      txt = document.selection.createRange().text;
    } else return;
    return txt;
  },
  getDataNode(data) {
    let divEle = document.createElement("div");
    divEle.innerHTML = data;
    return divEle;
  },

  deepEqual(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
      return true;
    }
    for (let key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return true;
      }
    }

    return false;
  },

  preventEmoji(event) {
    const emojiPattern =
      /[\u{1F600}-\u{1F64F}|\u{1F300}-\u{1F5FF}|\u{1F680}-\u{1F6FF}|\u{1F700}-\u{1F77F}|\u{1F800}-\u{1F8FF}|\u{2600}-\u{26FF}|\u{2700}-\u{27BF}|\u{FE00}-\u{FE0F}|\u{1F900}-\u{1F9FF}]/gu;
    const cleanedValue = event.target.value.replace(emojiPattern, "");
    return (event.target.value = cleanedValue);
  },
  preventEmojicontent(html) {
    const emojiPattern =
      /[\u{1F600}-\u{1F64F}|\u{1F300}-\u{1F5FF}|\u{1F680}-\u{1F6FF}|\u{1F700}-\u{1F77F}|\u{1F800}-\u{1F8FF}|\u{2600}-\u{26FF}|\u{2700}-\u{27BF}|\u{FE00}-\u{FE0F}|\u{1F900}-\u{1F9FF}]/gu;
    if (emojiPattern.test(html)) {
      const cleanedHtml = html.replace(emojiPattern, "");
      return cleanedHtml;
    } else {
      return html;
    }
  },

  filefivemb(e) {
    const selectedFile = e.target.files[0];
    const fileSizeInMB = selectedFile.size / (1024 * 1024);
    if (fileSizeInMB > 5) {
      alert("File size should be less than 5 MB");
      return false;
    } else {
      return true;
    }
  },
  splitTextAndYear(data) {
    let year = data.split(" ");
    const lastElement = year.at(-1);
    const isYearFormat = /^\d{2}$|^\d{4}$/.test(lastElement);

    const div = document.createElement("div");

    if (isYearFormat) {
      const mainText = year.slice(0, -1).join(" ");
      const yearText = lastElement;

      const p1 = document.createElement("p");
      p1.textContent = mainText;

      const p2 = document.createElement("p");
      p2.textContent = yearText;

      div.appendChild(p1);
      div.appendChild(p2);
    } else {
      const p1 = document.createElement("p");
      p1.textContent = data;
      div.appendChild(p1);
    }

    return div.outerHTML;
  },
};
