import React, { Component } from "react";
import { withRouter } from "react-router";
import covercolor1 from "../../../assets/images/cover_color_1.png";
import covercolor2 from "../../../assets/images/cover_color_2.png";

import Personalize from "../../../components/common/Personalize/Personalize";
import mixins from "../../../app-services/mixins";

export const RowWrapper = (props) => (
  <div className="row no-gutters">
    <div className="col"></div>
    {props.children}
    <div className="col"></div>
  </div>
);

export class Dedication extends Component {
  constructor(props) {
    super(props);
    initialConstruct.bind(this)("Dedication");
    this.state = {
      dedicationLenght:
        this.props.store?.customCharacter[8]?.ans?.dedication.length,
      dedicationStyle:
        this.props.store?.customCharacter[8]?.ans?.dedication.length == 125
          ? { color: "red" }
          : { color: "#000000" },
      dedicationText:
        this.props.store?.customCharacter[8]?.ans?.dedication ||
        "Believe in yourself and your dreams will come true! Love, Mum",
    };
  }

  setDedication(value) {
    let datatext = mixins.convertToHtmlEntities(value);
    console.log(datatext);
    let { onAnswer = () => "" } = this.props;
    AppLayout.setStore("customCharacter.8.ans", { dedication: datatext });
    onAnswer(value, "dedication");
  }
  defaultDedication() {
    if (!this.props.store?.customCharacter[8]?.ans?.dedication) {
      let { onAnswer = () => "" } = this.props;
      AppLayout.setStore("customCharacter.8.ans", {
        dedication:
          "Believe in yourself and your dreams will come true! Love, Mum",
      });
      onAnswer(value, "dedication");
    }
  }
  render() {
    let me = this;
    let { onSave = () => "" } = this.props;
    let { dedicationLenght, dedicationStyle, dedicationText } = this.state;
    return (
      <RowWrapper>
        <div className="col-lg-10 pt-2 dedication-container">
          <label className="bold-header">Write a special message here.:</label>
          <div>
            <textarea
              maxLength={125}
              defaultValue={dedicationText}
              onInput={(e) => me.debounce(e, me.setDedication, "setDedication")}
              onChange={(e) => {
                mixins.preventEmoji(e);
                this.setState({
                  dedicationLenght: e.target.value.length,
                });
                if (e.target.value.length == 125) {
                  this.setState({
                    dedicationStyle: { color: "red" },
                  });
                } else {
                  this.setState({
                    dedicationStyle: { color: "#000" },
                  });
                }
              }}
            ></textarea>
          </div>
          <span style={{ fontSize: "14px", ...dedicationStyle }}>
            {dedicationLenght ? dedicationLenght : dedicationText.length}/125
          </span>
          <div>
            <button
              className="savebtn"
              onClick={() => {
                onSave();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </RowWrapper>
    );
  }
}

export class CoverOptions extends Component {
  constructor(props) {
    super(props);
    initialConstruct.bind(this)("CoverOptions");
    this.state = {
      selected: "Hard Cover",
      options: [
        {
          cover: covercolor1,
          value: "Hard Cover",
          label: "Hard Cover",
          price: "13.90",
        },
        {
          cover: covercolor2,
          value: "Soft Cover",
          label: "Soft Cover",
          price: "13.90",
        },
      ],
    };
  }

  selectCover(obj) {
    let { options } = this.state;
    let { onAnswer = () => "" } = this.props;
    onAnswer(obj.value, "cover");
    //console.log({ selectCover: obj });
    this.setState({
      selected: obj.value,
      options: [...options],
    });
  }

  componentDidMount() {
    let { onAnswer = () => "" } = this.props;
    let { selected } = this.state;
    onAnswer(selected, "cover");
  }

  render() {
    let me = this;
    return (
      <RowWrapper>
        <div className="col-lg-10 pt-2 cover-container">
          {me.state.options.map((obj, i) => (
            <div onClick={(e) => me.selectCover(obj, i)} key={i}>
              <img src={obj.cover}></img>
              <div>
                <input
                  type={"checkbox"}
                  name="cover"
                  value={obj.value}
                  checked={me.state.selected == obj.value}
                  onChange={(e) => e.preventDefault()}
                ></input>
                <label>{obj.label}</label>
              </div>
            </div>
          ))}
        </div>
      </RowWrapper>
    );
  }
}

export class HandprintSave extends Component {
  render() {
    let { onSave = () => "", bookname } = this.props;
    return (
      <RowWrapper>
        {bookname == "handprint-book" && (
          <div className="col-lg-10 pt-2 dedication-container">
            <div>
              {/* <button className="savebtn" onClick={() => onSave()}>
                Save
              </button> */}
            </div>
          </div>
        )}
      </RowWrapper>
    );
  }
}

export const CheckoutFlow = [
  {
    name: "coveroption",
    subheading: "Select your Cover Page",
    Component: connect(withRouter(CoverOptions)),
    label: "Choose Your Cover Page",
  },
  {
    name: "edit",
    subheading: "Edit",
    Component: connect(withRouter(HandprintSave)),
    label: "Customize your book",
    onClick: () => {
      Modal.state.list.find((o) => o.type == "model").close();
      document.querySelector("#editBook")?.click();
    },
  },
  {
    name: "dedication",
    subheading: "Add a Personal Note",
    Component: connect(withRouter(Dedication)),
    label: "Write A Dedication",
  },
];
export default CheckoutFlow;
