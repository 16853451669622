import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import "./HandPrint.css";
import AppInput from "../../partials/AppInput";
import step01 from "../../assets/images/step1.png";
import step02 from "../../assets/images/step2.png";
import stepArrow from "../../assets/images/stepArrow.png";
import ImageInput from "./handContainer/handInput/ImageInput";
import Default_User_Pic from "./handContainer/handInput/demo-hand.png";
import hand_sample from "./../../assets/images/hand-sample.png";
import { useHistory } from "react-router-dom";
import mixins from "../../app-services/mixins";
let answer = {};
const Preinfo = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const history = useHistory();
  const [alertFail, setAlertFail] = useState(null);
  const [childHandImage, setChildhandimage] = useState("");
  const [parentHandImage, setParenthandimage] = useState("");
  const {
    alert,
    onChange = () => ({}),
    onSave = () => ({}),
    closable = false,
    onClose = () => alert.close(),
  } = props;
  const handprintPreInfo = props.store?.book?.handprintPreInfo || {};
  answer = {
    ...Object.deepClone(handprintPreInfo),
    ...(handprintPreInfo?.answer || {}),
  };
  const handleInput = (e) => {
    let inputValue = e.target.value;
    const words = inputValue.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
    );
    inputValue = capitalizedWords.join(" ");
    e.target.value = inputValue;
  };
  const handleChange = (val) => {
    console.log("Val", { handleChange: val });
    answer = { ...Object.deepClone(answer), ...val };
    console.log("child Hand", childHandImage?.photo?.src);
    //console.log({answer});
    mixins.lasycall(() => {
      onChange(answer);
    }, 500);
  };
  if (!answer.dedication) {
    handleChange({
      dedication: "You are special just the way you are.",
    });
  }
  const steps = ["1", "2", "3", "4"];
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <>
            <div className="maincontainer">
              <div className="image-flow">
                <div className="uploadcontainer">
                  <img src={step01} className="step1-image" />
                  <div className="centered">Step 01</div>
                </div>
                <div>
                  <img src={stepArrow} className="stepArrow-image" />
                </div>
                <div className="uploadcontainer">
                  <img src={step02} className="step2-image" />
                  <div className="centered_02">Step 02</div>
                </div>
              </div>
              <div className="first-row">What is the child's name. </div>
              <AppInput
                className="second-row"
                type={"text"}
                variant="filled"
                label={"Child name"}
                inputProps={{ pattern: "[a-zA-Z ]*" }}
                defaultValue={answer?.answer?.child_name || ""}
                onChange={(e) => {
                  mixins.preventEmoji(e);
                  handleInput(e);
                  handleChange({ ...answer, child_name: e.target.value });
                }}
              ></AppInput>
            </div>
          </>
        );
        1;

      case 1:
        return (
          <>
            <div className="maincontainer">
              <div className="image-flow">
                <div className="uploadcontainer">
                  <img src={step01} className="step1-image" />
                  <div className="centered">Step 01</div>
                </div>
                <div>
                  <img src={stepArrow} className="stepArrow-image" />
                </div>
                <div className="uploadcontainer">
                  <img src={step01} className="step2-image" />
                  <div className="centered_02">Step 02</div>
                </div>
              </div>
              <div className="first-row">What is your name. </div>
              {console.log("Parent", answer)}
              <AppInput
                className="second-row"
                type={"text"}
                variant="filled"
                label={"Your name"}
                inputProps={{ pattern: "[a-zA-Z ]*" }}
                defaultValue={answer?.answer?.parent_name || ""}
                onChange={(e) => {
                  mixins.preventEmoji(e);
                  handleInput(e);
                  handleChange({ ...answer, parent_name: e.target.value });
                }}
              ></AppInput>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="maincontainer">
              <div className="first-row">Dedication. </div>
              <textarea
                className="second-row"
                type={"textarea"}
                variant="filled"
                placeholder="You are special just the way you are."
                maxLength={300}
                label={"Your name"}
                onChange={(e) => {
                  mixins.preventEmoji(e);
                  handleChange({ ...answer, dedication: e.target.value });
                }}
                defaultValue={answer["dedication"] || ""}
              ></textarea>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className="popup">
              <div className="file-upload">
                <h3>Upload Your Handprints</h3>
                <div className="uploadInstruction">
                  <div className="instruction">
                    Instructions
                    <ul>
                      <li>Take a photo of your hand (palm facing camera)</li>
                      <li>
                        Take a photo of your child’s hand (palm facing camera)
                      </li>
                      <li>Photos should be against white background</li>
                      <li>
                        Fingers should be spaced slightly apart (as shown in
                        photo example)
                      </li>
                      <li>Image should be clear and without shadows</li>
                      <li>Images should be in jpg/jpeg/png format</li>
                      <li>Upload photos below</li>
                    </ul>
                    <p>* Preview of the book works better on desktop</p>
                  </div>
                  <div className="right">
                    <img src={hand_sample} className="right-image" />
                  </div>
                </div>
                <div className="fileUploadSection col-sm-12">
                  <div className="uploadsection child-upload col-sm-6">
                    <h4 className="uploadcategory">For Child</h4>
                    <ImageInput
                      imageData={childHandImage.photo?.src}
                      defaultPic={Default_User_Pic}
                      type="admin"
                      name="photo"
                      label="Add Photo"
                      showPreview
                      onChange={(file) => {
                        setChildhandimage(file);
                        handleChange({
                          ...answer,
                          child_handprint: file.photo.data,
                          parent_handprint: parentHandImage?.photo?.data,
                        });
                      }}
                    />
                  </div>
                  <div className="uploadsection col-sm-6">
                    <h4 className="uploadcategory">For Adult</h4>
                    <ImageInput
                      imageData={parentHandImage.photo?.src}
                      defaultPic={Default_User_Pic}
                      type="admin"
                      name="photo"
                      label="Add Photo"
                      showPreview
                      onChange={(file) => {
                        setParenthandimage(file);
                        handleChange({
                          ...answer,
                          parent_handprint: file.photo.data,
                          child_handprint: childHandImage?.photo?.data,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return "unknown step";
    }
  }

  //console.log(props);

  const handleNext = () => {
    // console.log(activeStep);
    delete answer.answer;
    let payload = Object.deepClone(answer);
    payload.answer = answer;
    //console.log("Payload",payload);
    mixins.setStore("book.handprintPreInfo", payload, props);
    if (isLast()) {
      if (
        activeStep === 3 &&
        answer.child_handprint &&
        answer.parent_handprint
      ) {
        onSave(answer, props.alert);
      } else {
        setAlertFail("Upload the handprint");
        setTimeout(() => {
          setAlertFail(null);
        }, 2000);
      }
    } else {
      if (activeStep === 0 && answer.child_name) {
        setActiveStep(activeStep + 1);
      } else if (activeStep === 1 && answer.parent_name) {
        setActiveStep(activeStep + 1);
      } else if (activeStep === 2 && answer.dedication) {
        setActiveStep(activeStep + 1);
      } else {
        setAlertFail("Enter the field");
        setTimeout(() => {
          setAlertFail(null);
        }, 2000);
      }
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  const isLast = () => {
    return activeStep == steps.length - 1;
  };
  const goBack = (alert) => {
    props.alert.close();
    history.push("/handprint-books/product-list");
  };

  return (
    <>
      <form onSubmit={(e) => (e.preventDefault(), handleChange())}>
        <div className="PreInfoCollection" id="handprint_pre_info_collection">
          {alertFail && (
            <Alert severity="error" variant="filled" className="alert">
              <strong>{alertFail}</strong>
            </Alert>
          )}
          <div className="row">
            <div className="col-12 step active">
              {/* {console.log("hell", steps, activeStep)} */}
              {getStepContent(activeStep)}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <button
                className="continue"
                variant="contained"
                color="primary"
                onClick={handleNext}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </button>
            </div>
          </div>
          <div className="row">
            {activeStep > 0 && (
              <button type="button" className="previous" onClick={handleBack}>
                BACK TO PREVIOUS STEP
              </button>
            )}
            {activeStep == 0 && (
              <button
                className="previous"
                onClick={() => {
                  closable
                    ? onSave(answer, props.alert)
                    : history.push(
                        `/handprint-books/product-details/${props.store.bookId}`
                      );
                  alert.close();
                }}
              >
                BACK TO PRODUCT STEP
              </button>
            )}
          </div>
          <div className="row">
            <span
              onClick={() =>
                closable ? onSave(answer, props.alert) : goBack()
              }
              style={{
                position: "absolute",
                top: "-15px",
                right: "-15px",
                padding: "5px 10px",
                background: " lightgray",
                borderRadius: "20px",
                cursor: "pointer",
              }}
            >
              <i className="fa fa-times"></i>
            </span>
          </div>
        </div>
      </form>
    </>
  );
};

export default connect(Preinfo);
