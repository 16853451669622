export const staticsproduct = {
  id: 5,
  title: "null",
  totalPages: 120,
  bookThumbnail: null,
  bookTitle: "This is Your World",
  shortDescription:
    "A story of hope, courage and wonder, as friends part ways and embark on new adventures.. In this whimsical tale through magical realms, remember your favourite moments with the ones you love, as you look towards the future. With memories so precious, the road ahead looks so much brighter!.",
  reviewId: null,
  ISBN: "152",
  publisherDate: "2022-03-12",
  publisherId: 1,
  fileId: 15,
  deleted_at: null,
  created_at: "2022-04-01T00:51:13.000000Z",
  updated_at: "2023-03-31T15:05:27.000000Z",
  sku: "SKU-01",
  category: "world",
  aboutBook:
    "An extraordinary adventure. One in which Ellie will discover the origins of her power and why she can speak to animals.\r\n\r\nA dangerous journey. One in which Ellie will understand the true meaning of sacrifice.\r\n\r\nA hopeful ending. One in which Ellie forges new friendships and learns to listen with her heart.",
  bookPrice: "00.00",
  display: 1,
  previewIdOne: null,
  previewIdTwo: null,
  previewIdThree: null,
  book_review_count: 4,
  book_rating_count: 6,
  fileData: {
    id: 15,
    fileName: "front_page.png",
    filePath:
      "https://e-books-customization.s3.ap-southeast-1.amazonaws.com/e-book-api/thisisyourworld/front_page.png",
    fileSize: "0.49 MB",
    uploadedBy: 1,
    mimeType: "image/jpeg",
    deleted_at: null,
    created_at: "2022-04-01T00:51:13.000000Z",
    updated_at: "2022-04-01T04:04:26.000000Z",
  },
  previewOne: null,
  previewTwo: null,
  previewThree: null,
  booksAuthor: {
    id: 1,
    authorId: 2,
    bookId: 1,
    createdBy: 1,
    deleted_at: null,
    created_at: "2022-04-01T00:51:13.000000Z",
    updated_at: "2023-06-19T10:53:00.000000Z",
    author: {
      id: 2,
      firstName: "Eliza",
      secondName: "Teoh",
      userId: 3,
      address: "Singapore",
      phone: "9856895696",
      email: "eliza.teoh@mailinator.com",
      type: "author",
      authorInfo:
        "Eliza Teoh, a Singapore Press Holdings scholar, was a journalist and sub-editor at The Straits Times. She tells everyone that Ellie Belly is based on her irrepressible and rambunctious younger daughter, but truth be told, Ellie Belly is a reflection of herself. When she was young, she was so boisterous that her mother told everyone that she was a boy and everyone believed it. Eliza and her real-life daughters love rescuing and fostering street dogs in Singapore.\n\nEliza has become one of Singapore's most loved and celebrated children's authors. The Ellie Belly series has hit The Straits Times bestseller list multiple times, with rights sold to China, India and Turkey. This is her 11th Ellie Belly book.",
      deleted_at: null,
      created_at: "2022-04-01T00:31:01.000000Z",
      updated_at: "2022-04-08T05:53:17.000000Z",
      active: 1,
    },
  },
  booksArtist: {
    id: 1,
    artistId: 3,
    bookId: 1,
    deleted_at: null,
    created_at: "2022-04-01T00:51:13.000000Z",
    updated_at: "2023-06-19T10:53:00.000000Z",
    createdBy: null,
    artist: {
      id: 3,
      firstName: "David",
      secondName: "Liew",
      userId: 4,
      address: "test address",
      phone: "9874586235",
      email: "david.liew@mailinator.com",
      type: "artist",
      authorInfo:
        "When Wolfe is not illustrating Ellie Belly, he is David Liew. Apart from being an illustrator, he is also a cartoonist, animator and sculptor. His illustrations have appeared in work for the Ministry of Education, the Ministry of Defence, the National Library Board and museums. He is also the founder and chief crafter at the Sleeping Iron Foundry, making models and miniatures.\n\nHe also illustrated Melanie Lee’s Squirky the Alien series which won the Samsung KidsTime Authors’ Award in 2016. Book 3 in that series Who is the Red Commander? Was awarded the Crystal Kite Award by the Society of Children’s Book Writers and Illustrators. That same year, he illustrated Japanese author Akiko Sueyoshi’s Monster Day on Tabletop Hill, an English-Japanese bilingual picture book specially commissioned for the Asian Festival of Children’s Content. \n\nDavid has authored and illustrated his own book, Nightmare on Eat Street, about a bunch of otherworldly misfits that gather at the weirdest eatery on earth. In 2018, he worked on The Plano Adventures with Hwee Goh and Mo Dirani. The completed series of five made its debut in the United Kingdom and United States in 2019.",
      deleted_at: null,
      created_at: "2022-04-01T00:31:51.000000Z",
      updated_at: "2022-04-01T00:31:51.000000Z",
      active: 1,
    },
  },
  booksGenre: {
    id: 1,
    bookId: 1,
    genreId: 1,
    createdBy: 1,
    deleted_at: null,
    created_at: "2022-04-01T00:51:13.000000Z",
    updated_at: "2023-06-19T10:53:00.000000Z",
    genre: {
      id: 1,
      name: "Literature",
      description: "Literature",
      createdBy: 1,
      updatedBy: 1,
      deleted_at: null,
      created_at: "2022-03-31T17:39:26.000000Z",
      updated_at: "2022-03-31T17:39:55.000000Z",
    },
  },
  bookDetail: {
    id: 1,
    bookId: 1,
    metaId: 1,
    ageGroups: "kids",
    personalized: 1,
    createdBy: 1,
    updatedBy: 1,
    deleted_at: null,
    created_at: "2022-04-01T00:51:13.000000Z",
    updated_at: "2022-04-04T08:06:08.000000Z",
    resource: null,
    downloadFileId: null,
    description:
      "A story of hope, courage and wonder, as friends part ways and embark on new adventures.. In this whimsical tale through magical realms, remember your favourite moments with the ones you love, as you look towards the future. With memories so precious, the road ahead looks so much brighter!.",
    dimension: "19.7*13",
    customHow:
      "• Enter the name of a child. We'll put their name on the front cover and throughout the story. \r\n• Customise the child's illustrated character – choose skin tone, hairstyle, hair colour and T-shirt colour. You can even add glasses!\r\n• Add your own free dedication to the first page",
    printedIn: "Singapore",
    otherInfo:
      "Each book is printed in vibrant colour on high-quality paper. \r\n• Softback size: 19.7cm x 13cm\r\n• 120 pages\r\n• Printed in Singapore",
    weight: "3.0",
    metric: "kg",
  },
  bookPublisher: {
    id: 1,
    publisherName: "PlotterBox",
    publisherDescription: "Plotter Box",
    createdBy: 1,
    deleted_at: null,
    created_at: "2022-03-31T17:40:59.000000Z",
    updated_at: "2022-03-31T17:41:15.000000Z",
  },
  bookReview: [],
  bookRating: [],
  product: {
    id: 11,
    itemId: 11,
    actualPrice: "00.00",
    productInfo: null,
    productDetails: null,
    standardPrice: null,
    customPrice: null,
    deleted_at: null,
    created_at: "2022-09-16T06:50:51.000000Z",
    updated_at: "2023-06-16T05:31:09.000000Z",
    tax: 0,
    bookTitle: "This is Your World",
    currency: "SGD",
    bookImageUrl:
      "https://e-books-customization.s3.ap-southeast-1.amazonaws.com/e-book-api/thisisyourworld/front_page.png",
    taxAmount: "8",
    type: "Percentage",
    item: {
      id: 11,
      bookId: 1,
      categoryId: 1,
      deleted_at: null,
      created_at: "2022-09-16T06:50:50.000000Z",
      updated_at: "2022-09-16T06:50:50.000000Z",
    },
  },
};
export const productdetails = {
  userId: 234,
  bookDetails: {
    id: 1,
    bookId: 1,
    metaId: 1,
    ageGroups: "kids",
    personalized: 1,
    createdBy: 1,
    updatedBy: 1,
    deleted_at: null,
    created_at: "2022-04-01T00:51:13.000000Z",
    updated_at: "2022-04-04T08:06:08.000000Z",
    resource: null,
    downloadFileId: null,
    description:
      "A story of hope, courage and wonder, as friends part ways and embark on new adventures.. In this whimsical tale through magical realms, remember your favourite moments with the ones you love, as you look towards the future. With memories so precious, the road ahead looks so much brighter!.",
    dimension: "19.7*13",
    customHow: [
      "• Enter the name of a child. We'll put their name on the front cover and throughout the story. ",
      "• Customise the child's illustrated character – choose skin tone, hairstyle, hair colour and T-shirt colour. You can even add glasses!",
      "• Add your own free dedication to the first page",
    ],
    printedIn: "Singapore",
    otherInfo:
      "Each book is printed in vibrant colour on high-quality paper. \r\n• Softback size: 19.7cm x 13cm\r\n• 120 pages\r\n• Printed in Singapore",
    weight: "3.0",
    metric: "kg",
    bookReviews: [],
    totalReview: 0,
    avgRating: {
      bookId: 5,
      averageRating: "0.0",
      totalRating: 0,
    },
    product: {
      id: 11,
      itemId: 11,
      actualPrice: "00.00",
      productInfo: [""],
      productDetails: null,
      standardPrice: null,
      customPrice: null,
      deleted_at: null,
      created_at: "2022-09-16T06:50:51.000000Z",
      updated_at: "2023-06-16T05:31:09.000000Z",
      tax: 0,
      bookTitle: "This is Your World",
      currency: "SGD",
      bookImageUrl:
        "https://d2j5powc1vcw3q.cloudfront.net/e-book-api/files/mkR2u3aIWP/elliebellie-cover.jpg",
      taxAmount: "8",
      type: "Percentage",
      referral: "false",
    },
    book: {
      id: 1,
      title: "null",
      totalPages: 120,
      bookThumbnail: null,
      bookTitle: "This is Your World",
      shortDescription:
        "A story of hope, courage and wonder, as friends part ways and embark on new adventures.. In this whimsical tale through magical realms, remember your favourite moments with the ones you love, as you look towards the future. With memories so precious, the road ahead looks so much brighter!.",
      reviewId: null,
      ISBN: "152",
      publisherDate: "2022-03-12",
      publisherId: 1,
      fileId: 15,
      deleted_at: null,
      created_at: "2022-04-01T00:51:13.000000Z",
      updated_at: "2023-03-31T15:05:27.000000Z",
      sku: "SKU-01",
      category: "world",
      aboutBook:
        "An extraordinary adventure. One in which Ellie will discover the origins of her power and why she can speak to animals.\r\n\r\nA dangerous journey. One in which Ellie will understand the true meaning of sacrifice.\r\n\r\nA hopeful ending. One in which Ellie forges new friendships and learns to listen with her heart.",
      bookPrice: "00.00",
      display: 1,
      previewIdOne: null,
      previewIdTwo: null,
      previewIdThree: null,
      booksAuthor: {
        id: 1,
        authorId: 2,
        bookId: 1,
        createdBy: 1,
        deleted_at: null,
        created_at: "2022-04-01T00:51:13.000000Z",
        updated_at: "2023-06-19T10:53:00.000000Z",
        author: {
          id: 2,
          firstName: "Eliza",
          secondName: "Teoh",
          userId: 3,
          address: "Singapore",
          phone: "9856895696",
          email: "eliza.teoh@mailinator.com",
          type: "author",
          authorInfo:
            "Eliza Teoh, a Singapore Press Holdings scholar, was a journalist and sub-editor at The Straits Times. She tells everyone that Ellie Belly is based on her irrepressible and rambunctious younger daughter, but truth be told, Ellie Belly is a reflection of herself. When she was young, she was so boisterous that her mother told everyone that she was a boy and everyone believed it. Eliza and her real-life daughters love rescuing and fostering street dogs in Singapore.\n\nEliza has become one of Singapore's most loved and celebrated children's authors. The Ellie Belly series has hit The Straits Times bestseller list multiple times, with rights sold to China, India and Turkey. This is her 11th Ellie Belly book.",
          deleted_at: null,
          created_at: "2022-04-01T00:31:01.000000Z",
          updated_at: "2022-04-08T05:53:17.000000Z",
          active: 1,
        },
      },
      booksArtist: {
        id: 1,
        artistId: 3,
        bookId: 1,
        deleted_at: null,
        created_at: "2022-04-01T00:51:13.000000Z",
        updated_at: "2023-06-19T10:53:00.000000Z",
        createdBy: null,
        artist: {
          id: 3,
          firstName: "David",
          secondName: "Liew",
          userId: 4,
          address: "test address",
          phone: "9874586235",
          email: "david.liew@mailinator.com",
          type: "artist",
          authorInfo:
            "When Wolfe is not illustrating Ellie Belly, he is David Liew. Apart from being an illustrator, he is also a cartoonist, animator and sculptor. His illustrations have appeared in work for the Ministry of Education, the Ministry of Defence, the National Library Board and museums. He is also the founder and chief crafter at the Sleeping Iron Foundry, making models and miniatures.\n\nHe also illustrated Melanie Lee’s Squirky the Alien series which won the Samsung KidsTime Authors’ Award in 2016. Book 3 in that series Who is the Red Commander? Was awarded the Crystal Kite Award by the Society of Children’s Book Writers and Illustrators. That same year, he illustrated Japanese author Akiko Sueyoshi’s Monster Day on Tabletop Hill, an English-Japanese bilingual picture book specially commissioned for the Asian Festival of Children’s Content. \n\nDavid has authored and illustrated his own book, Nightmare on Eat Street, about a bunch of otherworldly misfits that gather at the weirdest eatery on earth. In 2018, he worked on The Plano Adventures with Hwee Goh and Mo Dirani. The completed series of five made its debut in the United Kingdom and United States in 2019.",
          deleted_at: null,
          created_at: "2022-04-01T00:31:51.000000Z",
          updated_at: "2022-04-01T00:31:51.000000Z",
          active: 1,
        },
      },
      fileData: {
        id: 15,
        fileName: "elliebellie-cover.jpg",
        filePath:
          "https://e-books-customization.s3.ap-southeast-1.amazonaws.com/e-book-api/thisisyourworld/front_page.png",
        fileSize: "0.49 MB",
        uploadedBy: 1,
        mimeType: "image/jpeg",
        deleted_at: null,
        created_at: "2022-04-01T00:51:13.000000Z",
        updated_at: "2022-04-01T04:04:26.000000Z",
      },
      previewOne: null,
      previewTwo: null,
      previewThree: null,
    },
  },
  images: [
    "https://e-books-customization.s3.ap-southeast-1.amazonaws.com/e-book-api/thisisyourworld/front_page.png",
  ],
};
