import React, { useEffect, useState } from "react";
import phoneblue from "../../../assets/images/phoneblue.png";
import editblue from "../../../assets/images/editblue.png";
import deleteAddress from "../../../assets/images/deleteAddress.png";
import location from "../../../assets/images/location.png";
import back from "../../../assets/images/back.png";
import s from "../../../assets/images/s.png";
import { useHistory } from "react-router-dom";
import Alert from "@mui/material/Alert";

import CircularProgress from "@mui/material/CircularProgress";
export default connect((props) => {
  const history = useHistory();
  const [address, setAddress] = useState([]);
  const [billaddress, setBilladdress] = useState([]);
  const [delivery, setDelivery] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedDelivery, setSelectedDelivery] = useState("");
  const [alertFail, setAlertFail] = useState(null);
  const [loading, setLoading] = useState("none");
  useEffect(() => {
    getAllAddressDetails();
  }, []);
  useEffect(() => {
    if (props.store.deliveryDetails && props.store.deliveryDetails.id) {
      setSelectedDelivery(props.store.deliveryDetails.id);
    }
    if (props.store.fulladdress && props.store.fulladdress.id) {
      setSelectedAddress(props.store.fulladdress.id);
    }
  }, []);

  // delete shipping address
  const deleteShippingDetails = (id) => {
    setLoading("flex");
    api.deleteShippingItem(id).then((res) => {
      setLoading("none");
      if (res && res.statusCode === 200) {
        getAllAddressDetails();
      }
    });
  };

  //Get all cities bu country Id
  const getAllAddressDetails = () => {
    setLoading("flex");
    api.getAllAddress().then((res) => {
      setLoading("none");
      if (
        res &&
        res.statusCode === 200 &&
        res.data &&
        res.data.Addresses &&
        res.data.deliveryTypes
      ) {
        //console.log(res.data.BillingAddress[0]);
        mixins.setStore("AddressList", res.data.Addresses, props);
        mixins.setStore(
          "BillingAddress",
          res.data.BillingAddress[res.data.BillingAddress.length - 1],
          props
        );
        let defaultAddress = res.data.Addresses.find((o) => o.default);
        if (defaultAddress) {
          mixins.setStore("fulladdress", defaultAddress, props);
          setSelectedAddress(defaultAddress.id);
        }
        else {
          mixins.setStore("fulladdress", {}, props);
          setSelectedAddress();
        }
        setAddress(res.data.Addresses);
        setDelivery(res.data.deliveryTypes);
        setBilladdress(
          res.data.BillingAddress[res.data.BillingAddress.length - 1]
        );
      }
    });
  };
  //To select address
  const handleChangeAddress = (id) => {
    setSelectedAddress(id);
    {
      address.length >= 1 &&
        address.map((data, index) => {
          if (data.id === id) {
            mixins.setStore("fulladdress", data, props);
          }
        });
    }
  };
  //To select address
  const handleChangeDelivery = (data) => {
    setSelectedDelivery(data);
    {
      delivery.length >= 1 &&
        delivery.map((value, index) => {
          if (value.id === data) {
            mixins.setStore("deliveryDetails", value, props);
          }
        });
    }
  };
  //onClick Edit Address Button
  const onClickEditAddress = (id) => {
    // console.log("Editable Address:",props.store.AddressList.filter((item)=>(item.id == id))[0]);
    mixins.setStore(
      "EditableAddress",
      props.store.AddressList.filter((item) => item.id == id)[0],
      props
    );
    if (selectedAddress) {
      mixins.setStore("deliveryDetails", "", props);
      history.push(`/edit-address/${id}`);
    } else {
      setAlertFail("Please choose Your address");
      setTimeout(() => {
        setAlertFail(null);
      }, 6000);
    }
  };
  const onClickPayment = () => {
    if (selectedAddress && selectedDelivery) {
      mixins.setStore("address", selectedAddress, props);
      mixins.setStore("deliverytype", selectedDelivery, props);

      history.push("/payment");
    } else if (selectedAddress) {
      setAlertFail("Please Select Shipping Details");
      setTimeout(() => {
        setAlertFail(null);
      }, 8000);
    } else {
      setAlertFail("Please Choose Your Address");
      setTimeout(() => {
        setAlertFail(null);
      }, 8000);
    }
  };
  useEffect(() => {
    mixins.setStore("EditableAddress", "", props);
  }, []);

  const fetchcountry = (countryname) => {
    if (countryname) {
      let country = countryname == "SG" ? "Singapore" : countryname;
      api
        .fetchgstbycountryname(country)
        .then((res) => {
          if (res.status) {
            let gstdata = {
              countryname: res.data[0]?.name,
              gst_rate: res.data[0]?.gst_rate || 0,
            };
            mixins.setStore("contrywithgst", gstdata, props);
          }
        })
        .catch((rej) => {
          console.log(rej);
          
        });
    }
  };
  useEffect(() => {
    if (props.store?.fulladdress?.id) {
      let defaultaddress = props.store?.fulladdress;
      let countryname = defaultaddress?.country;
      fetchcountry(countryname);
    }else{
      mixins.setStore("contrywithgst", {}, props);
    }
  }, [props.store?.fulladdress]);
  return (
    <div className="deliverycard">
      {alertFail && (
        <Alert severity="error" variant="filled">
          <strong>{alertFail}</strong>
        </Alert>
      )}
      <div style={{ display: loading, margin: "auto" }}>
        <CircularProgress color="success" />
      </div>
      {address.length >= 1 &&
        address.map((data, index) => {
          return (
            <>
              <div className="delivery-address-container" key={index}>
                <div className="delivery-address-container-row">
                  <div
                    className="delivery-address-container-col-left"
                    style={{ fontWeight: "bold", fontFamily: "Quicksand-Bold" }}
                  >
                    <div>
                      <input
                        type="radio"
                        checked={data.id === selectedAddress}
                        onChange={() => handleChangeAddress(data.id)}
                      />
                      Deliver to : {data.firstName + " "}
                      {data.lastName}
                    </div>
                  </div>
                  {/* <div className='delivery-address-container-col'><label><img src={phoneblue} />Call Customer</label></div> */}
                </div>
                <div className="delivery-address-container-row">
                  <div className="Address">
                    <div className="delivery-address-container-col-left">
                      <div className="delivery-address-container-col-left-row">
                        <div className="delivery-address-container-col-left-colm">
                          Shipping Address:
                        </div>
                        <div className="delivery-address-container-col-left-col">
                          <img src={location} className="location" />
                          <textarea
                            value={`${
                              data?.address1 === null ? "" : data?.address1
                            } ${
                              data?.address2 === null ? "" : data?.address2
                            } ${data?.city || ""} ${data?.state || ""} ${
                              data?.zipCode
                            }, ${data?.country}`}
                            type="textarea"
                          />
                        </div>
                      </div>
                    </div>
                    {billaddress?.address1 && (
                      <>
                        <div className="delivery-address-container-col-left">
                          <div className="delivery-address-container-col-left-row">
                            <div className="delivery-address-container-col-left-colm">
                              Billing Address:
                            </div>

                            <div className="delivery-address-container-col-left-col">
                              <img src={location} className="location" />

                              <textarea
                                value={`${billaddress?.address1} ${
                                  billaddress?.address2
                                } ${billaddress?.city || ""} ${
                                  billaddress?.state || ""
                                }  ${billaddress?.zipCode}, ${
                                  billaddress?.country
                                }`}
                                type="textarea"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="editbtn-container">
                    <div
                      className="delivery-address-container-col"
                      onClick={() => {
                        onClickEditAddress(data.id);
                      }}
                    >
                      <label>
                        <img src={editblue} />
                        Edit Address
                      </label>
                    </div>
                    <div
                      className="delivery-address-container-col"
                      onClick={() => deleteShippingDetails(data.id)}
                    >
                      <label>
                        <img src={deleteAddress} />
                        Delete Address
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      <div className="title">Shipping Details</div>
      <div className="delivery-address-container">
        {delivery.length >= 1 &&
          delivery.map((value, index) => {
            return (
              <div className="delivery-type-container-row">
                <div className="delivery-type-radio">
                  <input
                    type="radio"
                    checked={value.id === selectedDelivery}
                    onChange={() => handleChangeDelivery(value.id)}
                  />
                </div>
                <div className="delivery-type-des">
                  <span className="titles">{value.shippingType}</span>
                  <label className="date">
                    {" "}
                    Estimated delivery: {value.estimatedDelhiveryDate}
                  </label>
                  <span className="info">{value.description}</span>
                </div>
              </div>
            );
          })}
      </div>
      <div className="button-container">
        <button
          className="back-btn"
          onClick={() => {
            history.push("/checkout-page");
          }}
        >
          <span>
            <img src={back} />
            Back to Shipping details
          </span>
        </button>
        {address.length >= 1 && (
          <button className="continue-btn" onClick={onClickPayment}>
            <span>
              <img src={s} />
              Continue to Payment
            </span>
          </button>
        )}
      </div>
    </div>
  );
});
