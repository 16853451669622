import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
const paypalScriptOptions = {
  "client-id":
    "AUrlRZ5qTsdMhuZ0bz0kL2ur3pLtSyd4vbmjtYD5bHXSfLB4jiUPvYlA0iaGeOdLcEerjn-GAhtuOU8I",
  currency: "SGD",
};

function Button() {
  const price = useSelector((state) => state.Total);
  const subTotal = useSelector((state) => state.CartDetails.subTotal);
  const deliveryType = useSelector((state) => state.deliveryDetails.id);
  const shippingId = useSelector((state) => state.fulladdress.id);
  const discount = useSelector((state) => state.dicount);
  const couponCode = useSelector((state) => state.currentCoupon);
  const tax = useSelector((state) => (state.totaltaxamount == 0 ? 0 : 1));
  const totalTax = useSelector((state) => state.gstamount);
  const shipping = useSelector((state) => state.deliveryDetails.price);
  const customizedId = useSelector((state) =>
    state.cart_list.cart.map((o) => {
      if (
        o.customBookDetails.customBookType == "customcharacter" ||
        o.customBookDetails.customBookType == "handprint"
      ) {
        return [
          o.customBookDetails.customBookType,
          o.customBookDetails.customizedId,
        ];
      }
    })
  );

  const history = useHistory();
  /**
   * usePayPalScriptReducer use within PayPalScriptProvider
   * isPending: not finished loading(default state)
   * isResolved: successfully loaded
   * isRejected: failed to load
   */
  // console.log(
  //   "prize",
  //   price,
  //   "Subtotal",subTotal,
  //   "Dtype",deliveryType,
  //   "ShippingID",shippingId,
  //   "discount",discount,
  //   "couponCode",couponCode,
  //   "tax",tax,
  //   "totaltax",totalTax,
  //   "shiping",shipping,
  // );
  const [{ isPending }] = usePayPalScriptReducer();
  const paypalbuttonTransactionProps = {
    style: { layout: "vertical", innerWidth: "100px" },
    createOrder(data, actions) {
      return actions.order.create({
        purchase_units: [
          {
            amount: {
              value: price,
            },
          },
        ],
      });
    },
    onApprove(data, actions) {
      /**
       * data: {
       *   orderID: string;
       *   payerID: string;
       *   paymentID: string | null;
       *   billingToken: string | null;
       *   facilitatorAccesstoken: string;
       * }
       */
      return actions.order.capture({}).then((details) => {
        //console.log("paypal response", details);
        const paypalresponse = {
          amount: details.purchase_units[0].amount.value,
          currency: details.purchase_units[0].amount.currency_code,
          orderId: details.id,
          orderDate: details.create_time,
          payerId: details.payer.payer_id,
          paymentId: details.purchase_units[0].payments.captures[0].id,
          paymentComments: JSON.stringify(
            details.purchase_units[0].payee,
            null,
            2
          ),
          paymentStatus: details.status,
        };
        //console.log("paypal response paypal", paypalresponse);

        api
          .cashonDeliveryMethod({
            tax: tax,
            totalTax: totalTax,
            subTotal: subTotal,
            userId: "",
            shipping: 0,
            shippingCharge: shipping,
            grandTotal: price,
            stripeOrderId: "",
            totalDiscount: discount,
            stripePaymentId: "",
            paymentType: "Paypal",
            customizedId: JSON.stringify(customizedId),
            deliveryTypeId: deliveryType,
            shippingAddressId: shippingId,
            promotionCode: couponCode,
            //"promotionId":couponCodeId
          })
          .then((res) => {
            //console.log(res);
            if (res && res.data && res.statusCode === 200) {
              localStorage.setItem("orderId", res.data.id);
              const paypalfres = {
                ...paypalresponse,
                bookOrderId: res.data.id,
              };
              // console.log("PP",pp);
              //console.log("Details",details);
              api.paymentCreate(paypalfres).then((res) => {
                if (res && res.data && res.statusCode === 200) {
                  history.push("/order-confirmation");
                } else {
                  alert("Transaction failed");
                }
                //console.log(res, "resrsersesrse");
              });
            } else {
              if (res && res.errorMessages) {
                alert(res.errorMessages[0]);
              } else {
                alert(res.message);
              }
            }
          });
      });
    },
  };
  return (
    <>
      {isPending ? <h2>Load Smart Payment Button...</h2> : null}
      <PayPalButtons {...paypalbuttonTransactionProps} />
    </>
  );
}
export default function App() {
  return (
    <div
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* <h1>Hello PayPal</h1> */}
      <div style={{ width: "500px" }}>
        <PayPalScriptProvider options={paypalScriptOptions}>
          <Button />
        </PayPalScriptProvider>
      </div>
    </div>
  );
}
