import React from "react";

const Stepbar = (props) => {
  const {
    handleopen = () => {},
    preinfoCompleted,
    addtocart = () => {},
    handlePreinfoSave = () => {},
  } = props;
  return (
    <div className="container worldbook-stepbar">
      <div className="row no-gutters space">
        <div className="col-12">
          <div className="d-flex align-item-center custom-flex">
            <div
              className="action-button"
              onClick={() => {
                handleopen("name");
              }}
            >
              Edit Name
            </div>
            <div
              className="action-button"
              onClick={() => {
                handleopen("bookinfo");
              }}
            >
              Customise Your Story
            </div>
            <div
              className="action-button"
              onClick={() => {
                handleopen("imageupload");
              }}
            >
              Upload Your Photos
            </div>
            {preinfoCompleted ? (
              <>
                {(preinfoCompleted == "name" ||
                  preinfoCompleted == "bookinfo" ||
                  preinfoCompleted == "imageupload") && (
                  <div
                    className="action-button"
                    onClick={() => {
                      handlePreinfoSave();
                    }}
                  >
                    Save & Preview
                  </div>
                )}
              </>
            ) : (
              <div
                className={`action-button ${
                  preinfoCompleted ? "disabled" : ""
                }`}
                onClick={addtocart}
              >
                Save & Add to Cart
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(Stepbar);
