import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import "./Profile.css";

export default connect((props) => {
  const form = useRef();
  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    address1: "",
    address2: "",
  });
  // const [alertFail, setAlertFail] = useState(null);
  // const [alert, setAlert] = useState(null);
  const [userAddress, setUserAddress] = useState({});
  const user = useSelector((state) => state.user);
  //console.log(profile);

  useEffect(() => {
    getProfileDetails().then((res) => {
      //console.log(res);
      getProfileAddressDetails(res.data);

      // updateAddressDetails(res.data);
    });
  }, []);

  const isCustomer = function () {
    return profile && profile.roles && profile.roles[0]?.name === "customer";
  };

  const getProfileDetails = () => {
    return new Promise((resolve, reject) => {
      api.getuserProfile().then(resolve).catch(reject);
    });
  };
  // const updateAddressDetails = () => {
  //   return new Promise((resolve, reject) => {
  //     api.updateAddress().then(resolve).catch(reject);
  //   });
  // };

  const getProfileAddressDetails = (pdata) => {
    api.getProflieAddress(user.id).then((res) => {
      let autor = pdata.author;
      let data = res.data;
      if (res && data && res.statusCode === 200) {
        if (data) {
          //console.log("data", data.Addresses);
          let addresslen = data?.Addresses?.length;
          let defaultAddress = data.Addresses.find((o) => o.default);
          let Addresses = addresslen
            ? defaultAddress
              ? defaultAddress
              : data.Addresses[0]
            : {};
          setUserAddress(Addresses);
          let address1 = addresslen ? `${Addresses?.address1}` : autor?.address;
          let address2 = addresslen ? `${Addresses?.address2}` : "";
          //console.log({ Addresses, profile, pdata });
          setProfile({
            ...pdata,
            ...profile,
            ...Addresses,
            // firstName: autor ? pdata?.firstName : Addresses?.firstName,
            // lastName: autor ? pdata?.lstName : Addresses?.lastName,
            firstName: pdata?.firstName,
            lastName: pdata?.lastName,
            phone: Addresses?.contactNo || pdata?.phone,
            email: pdata?.email,
            address1,
            address2,
          });
        }
      }
    });
  };

  const validateData = (data) => {
    let message = [];
    // if (!data.contactNo.toString().startsWith("65")) {
    //   message.push(
    //     "Invalid Phone number, please enter number which should starts with country code"
    //   );
    // }
    if (message.length) {
      alert(message.join(","));
    }
    return !message.length;
  };

  const updateProfile = (e) => {
    e.preventDefault();
    let formData = form.current.getData();
    let valid = validateData(formData);
    if (!valid) {
      return;
    }
    api
      .updateAddress(formData, userAddress.id || 0)
      .then((res) => {
        // console.log(res);

        alert("Profile Updated Successfully");

        mixins.setStore("fulladdress", res.data, props);
      })
      .catch(($error) => {
        //console.log({ $error });
      });
  };

  const handleInavlid = (e) => {
    //console.log({ e });
    e.target.setCustomValidity("Enter your valid phone number.");
  };

  return (
    <div className="profileContainer">
      <form
        className="formContainer"
        ref={form}
        onSubmit={(e) => updateProfile(e)}
      >
        <div className="title">Profile</div>
        <div className="rowcontainer">
          <div className="columncontainer">
            <i className="fas fa-user icon"></i>
            <input
              name="firstName"
              className="input"
              type="text"
              defaultValue={profile.firstName}
              readOnly={true}
            />
            <input type="hidden" name="default" value={1}></input>
            <input type="hidden" name="userId" value={user?.id}></input>
          </div>
          <div className="columncontainer">
            <i className="fas fa-user icon-right"></i>
            <input
              name="lastName"
              className="input-right"
              type="text"
              defaultValue={profile.lastName}
              readOnly={true}
            />
          </div>
        </div>
        <div className="rowcontainer">
          <div
            className="columncontainer"
            title="Please enter your phone number with country code"
          >
            {/* <i class="fa fa-mobile icon" ></i> */}
            <i className="fas fa-phone-alt icon"></i>
            <input
              name="contactNo"
              className="input"
              type="number"
              onInput={(e) => (e.target.value = e.target.value.slice(0, 15))}
              placeholder="Ex 659XXXXXXXXX"
              defaultValue={profile.phone}
              required
            />
          </div>
          <div className="columncontainer">
            <i className="fas fa-envelope icon-right"></i>
            <input
              name="email"
              className="input-right"
              type="text"
              placeholder="Email"
              defaultValue={profile.email}
              readOnly={true}
            />
          </div>
        </div>
        <div className="rowcontainer">
          <div className="columncontainer">
            <i className="fas fa-home icon"></i>
          </div>
          <textarea
            name="address1"
            className="input inputbackground"
            type="text"
            placeholder="House/Apt Number, Street Name"
            defaultValue={profile.address1}
            required
          />
        </div>

        {isCustomer() && (
          <>
            <div className="rowcontainer">
              <div className="columncontainer">
                <i className="fa fa-map-marker icon"></i>
              </div>
              <input
                name="address2"
                className="input inputbackground"
                type="text"
                placeholder="Address line 2"
                defaultValue={profile.address2 ?? ""}
                required
              />
            </div>
          </>
        )}
        {profile && profile.roles && profile.roles[0].name === "customer" && (
          <div className="rowcontainer">
            <div className="columncontainer">
              <i className="fa fa-building icon"></i>
              <input
                name="city"
                className="city-row"
                type="text"
                placeholder="City"
                defaultValue={profile.city}
                disabled
              />
            </div>

            {/* <div className="columncontainer">
              <i className="fa fa-building icon-right"></i>
              <input
                name="state"
                className="input-right"
                type="text"
                placeholder="State"
                defaultValue={profile.state}
              />
            </div> */}
          </div>
        )}
        {profile && profile.roles && profile.roles[0].name === "customer" && (
          <div className="rowcontainer">
            <input type="hidden" name="default" value="1" hidden />
            <div className="columncontainer">
              <i className="fa fa-globe icon"></i>
              <input
                name="country"
                className="input"
                type="text"
                placeholder="Country"
                defaultValue={profile.country}
                required
              />
            </div>
            <div className="columncontainer">
              <i className="fa fa-map-pin icon-right"></i>
              <input
                name="zipCode"
                className="input-right"
                type="number"
                onInput={(e) => (e.target.value = e.target.value.slice(0, 6))}
                placeholder="Postcode"
                defaultValue={profile.zipCode}
                // oninvalid={console.log}
                required
              />
            </div>
          </div>
        )}
        {/* {profile && profile.author && (
          <div className="rowcontainer">
            <div className="columncontainer">
              <i className="fas fa-asterisk icon"></i>
            </div>
            <textarea
              className="textinput"
              type="text"
              defaultValue={profile.author.authorInfo}
            />
          </div>
        )} */}
        {profile && profile.roles && profile.roles[0].name === "customer" && (
          <button type="submit" className="saveAddress">
            Save
          </button>
        )}
      </form>
    </div>
  );
});
