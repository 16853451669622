import React, { useState } from "react";
import "./modal.css";
import { Modal } from "react-bootstrap";

const Modelwrapper = ({ open, handleClose, children }) => {
  return (
    <Modal
      size="lg"
      show={open}
      //onHide={handleClose}
      animation={true}
      className="custom-modal"
    >
      <div className="closeBtn">
        <i className="fa fa-times" onClick={handleClose} aria-hidden="true"></i>
      </div>

      {children}
    </Modal>
  );
};

export default Modelwrapper;
