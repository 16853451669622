import React, { useEffect, useRef, useState } from "react";
import titleimg from "../../../../assets/images/worldbook/left-dedication.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Dedication = (props) => {
  const {
    formdata,
    setFormdata,
    handlePreinfoSave = () => {},
    handlebackbtn = () => {},
  } = props;
  const [alert, setAlert] = useState(null);
  const [limit, setLimit] = useState(450);
  const nameRef = useRef();
  const handleChange = (val) => {
    setFormdata(val);
  };
  const handlesubmit = (e) => {
    e.preventDefault();
    handlePreinfoSave();
  };
  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(null);
      }, 2000);
    }
  }, [alert]);
  useEffect(() => {
    if (formdata?.dedication) {
      let count = stripHtmlAndCountText(formdata?.dedication);
      setLimit(450 - count);
    } else {
      setLimit(450);
    }
  }, [formdata]);

  const stripHtmlAndCountText = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    const textNodes = Array.from(tempDiv.childNodes)
      .filter(
        (node) =>
          node.nodeType === Node.TEXT_NODE ||
          (node.nodeType === Node.ELEMENT_NODE && node.tagName !== "BR")
      )
      .map((node) => node.textContent.trim())
      .filter((text) => text.length > 0);
    return textNodes.join(" ").length;
  };

  return (
    <div id="msform">
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="picture">
                <img src={titleimg} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="rt-content">
                <h4>
                  More space for a special message or leave it blank for
                  autographs
                </h4>
                <ReactQuill
                  theme="snow"
                  value={formdata?.dedication || ""}
                  onChange={(content) => {
                    let new_content = mixins.preventEmojicontent(content);
                    let count = stripHtmlAndCountText(new_content);
                    if (count <= 450) {
                      handleChange({ ...formdata, dedication: new_content });
                    } else {
                      handleChange({ ...formdata });
                    }
                  }}
                  placeholder="A special message"
                  modules={{
                    toolbar: false, // Disable the toolbar
                    clipboard: {
                      matchVisual: false, // Optional: prevent visual formatting
                    },
                  }}
                  className="editor"
                />
                <span className="limit">{limit}</span>
                {alert && (
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    {alert}
                  </span>
                )}

                <div className="save-btn">
                  <button className="action-button" onClick={handlesubmit}>
                    Save & Back to List
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(Dedication);
