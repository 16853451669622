import React, { useEffect, useRef, useState } from "react";
import titleimg from "../../../../assets/images/worldbook/book-title.jpg";

const Booktitle = (props) => {
  const {
    formdata,
    setFormdata,
    handlePreinfoSave = () => {},
    handlebackbtn = () => {},
  } = props;
  const [alert, setAlert] = useState(null);
  const [limit, setLimit] = useState(28);
  const nameRef = useRef();
  const handleChange = (val) => {
    setFormdata(val);
  };
  const handlesubmit = (e) => {
    e.preventDefault();
    if (!formdata?.book_title?.trim()) {
      setAlert("Field is required.");
      nameRef.current.focus();
    } else {
      handlePreinfoSave();
    }
  };
  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(null);
      }, 2000);
    }
  }, [alert]);
  useEffect(() => {
    if (formdata?.book_title) {
      setLimit(28 - formdata?.book_title?.length || 0);
    } else {
      setLimit(28);
    }
  }, [formdata]);
  return (
    <div id="msform">
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="picture">
                <img src={titleimg} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="rt-content">
                <h4>What book is the bunny reading? </h4>
                <p>
                  Name a favourite book or create your own book title here!{" "}
                </p>
                <input
                  ref={nameRef}
                  type="text"
                  placeholder="Please enter book title"
                  className="m-0"
                  maxLength={28}
                  value={formdata?.book_title || ""}
                  onChange={(e) => {
                    mixins.preventEmoji(e);
                    handleChange({ ...formdata, book_title: e.target.value });
                  }}
                />
                <span className="limit">{limit}</span>
                {alert && (
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    {alert}
                  </span>
                )}

                <div className="save-btn">
                  <button className="action-button" onClick={handlesubmit}>
                    Save & Back to List
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(Booktitle);
